<template>
  <div class="productions">
    <el-form>
      <el-form-item label="客户名称:" v-if="type==0">
        <el-select v-model="form.userId" clearable>
          <el-option v-for="item in clientList"  :key="item.userId" :value="item.userId" :label="item.nickName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称:">
        <el-select v-model="form.productId" :disabled="type == 1" @change="prochange" clearable>
          <el-option :label="item.productName" v-for="item in proList" :key="item.productId" :value="item.productId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单编号:" v-if="type==1">
        <span>{{form.orderNo}}</span>
      </el-form-item>
      <el-form-item v-if="form.systemName" label="所属系统">
        <span>{{form.systemName}}</span>
      </el-form-item>
      <el-form-item v-if="form.productPrice" label="产品价格">
        <span>{{form.productPrice}}</span>
      </el-form-item>
      <el-form-item label="购买用户:" v-if="type==1">
        <span>{{form.nickName}}</span>
      </el-form-item>
      <el-form-item label="联系电话:" v-if="type==1">
        <span>{{form.userPhone}}</span>
      </el-form-item>
      <el-form-item v-if="type==0">
        <myButton title="确认并开通" @myclick="submit" />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
      clientList:[],
      proList:[],
      form:{
        userId:'',
        productId:'',
        systemName:'',
        orderPrice:'',
        userPhone:'',
        pageSize:999
      },
      type:0,
    };
  },
  created(){
      this.getClient();
      this.getProList()
      this.type = this.$route.query.type;
      if(this.$route.query.id != 'undefined'){
        this.getDetail(this.$route.query.id)
      }
  },
  methods:{
    getDetail(id){
       this.$request.HttpGet('/admin/cmsOrder/find',{id})
       .then(res =>{
         console.log(res);
         this.form = res.data || {}
       })
    },
    getClient(){
      this.$request.HttpGet('/admin/cmsUser/list')
      .then(res => {
        this.clientList = res.data.list || []
        console.log(res)
      })
    },
    getProList(){
        this.$request.HttpGet('/admin/cmsProduct/list',this.form)
        .then(res => {
          this.proList = res.data.list || []
        })
    },
    prochange(e){
      let data = this.proList.reduce((obj,item) => {
        if(item.productId == e){
          obj = item
        }
        return obj
      },{})
     this.form.systemName = data.systemName;
     this.form.productPrice = data.productPrice;
    },
    submit(){
      this.$request.HttpPost('/admin/cmsOrder/create',this.form)
      .then(res => {
        if(res.code == 0){
          this.$plugin.notifySuccess(res.msg);
          this.$router.back()
        }
      })
    }
  }
};
</script>
<style lang='scss'>
</style>